import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ItemSubTask } from '../common/ItemSubTask'
import { useFeins } from '../../hooks/graph/useFeins'
import { Ein, FeinsQuery, Item } from '@local/api/generated/gql/graphql'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../common/loader'
import { Error } from '../../common/error'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { Badge } from '@toasttab/buffet-pui-badge'
import { AccordionItem } from '@toasttab/buffet-pui-accordion'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import { useCompanyCode } from '@toasttab/ec-session'

export enum StateTaxType {
  WITHHOLDING,
  UNEMPLOYMENT
}

const isTaxType = (
  ein: FeinsQuery['feins'][0]['states'][0]['eins'][0],
  type: StateTaxType
) => {
  switch (type) {
    // currently only two EIN types, and only way to infer withholding is by it not being SUTA
    case StateTaxType.WITHHOLDING:
      return !ein.sutaRates?.length
    case StateTaxType.UNEMPLOYMENT:
      return !!ein.sutaRates?.length
    default:
      return true
  }
}

const findEinTaxRate = (eins: [Ein], type: StateTaxType) => {
  for (const ein of eins) {
    if (isTaxType(ein, type)) {
      if (type === StateTaxType.UNEMPLOYMENT) {
        if (!ein.sutaRates || ein.sutaRates.length === 0) return undefined

        const rate = ein.sutaRates?.reduce(
          (prev, curr) =>
            prev +
            (curr.isExempt || curr.excludeFromCombinedRate
              ? 0
              : curr.rate || 0),
          0
        )

        return `${parseFloat(rate.toFixed(4))}%`
      }

      if (type === StateTaxType.WITHHOLDING) {
        if (ein.frequency) return ein.frequency.name
      }
    }
  }

  return undefined
}

export const StateTax = ({
  item,
  type,
  isAdmin
}: {
  item: Item
  type: StateTaxType
  isAdmin: boolean
}) => {
  const { data, loading, error } = useFeins()
  const { t } = useTranslation('ponc')
  const client = useCompanyCode()

  if (loading) return <Loader />

  if (error || !data) return <Error />

  const feins = data
  const uniqueFein = feins.length === 1 ? feins[0] : null
  const uniqueState =
    uniqueFein?.states.length === 1 ? uniqueFein.states[0] : null
  const uniqueEin = (
    uniqueState?.eins.filter((ein) => isTaxType(ein, type)).length === 1
      ? uniqueState.eins.filter((ein) => isTaxType(ein, type))[0]
      : null
  ) as Ein

  return (
    <ItemSubTask
      title={t(`${item.key}.stepTitle`)}
      content={
        <div className='flex flex-col mb-6'>
          <p>{t(`${item.key}.description`)}</p>
          {uniqueEin ? (
            <SingleStateRow
              item={item}
              ein={uniqueEin}
              rate={findEinTaxRate([uniqueEin], type)}
            />
          ) : (
            <StateTaxContent item={item} type={type} feins={feins} />
          )}
        </div>
      }
      footer={
        isAdmin && uniqueEin ? (
          <Button
            onClick={() => {
              window.location.assign(
                `/mvc/${client}/Company/Setup/TaxAccounts?feinUuid=${
                  uniqueFein?.id
                }&ein=${uniqueEin.id}&redirect=${window.location.pathname.slice(
                  1
                )}`
              )
            }}
          >
            {t(`${item.key}.edit`)}
          </Button>
        ) : undefined
      }
    />
  )
}

const StateTaxContent = ({
  item,
  type,
  feins
}: {
  item: Item
  type: StateTaxType
  feins?: FeinsQuery['feins']
}) => {
  if (!feins) {
    return null
  }

  const isMultiLocation = feins.length > 1

  return (
    // refer to index.css for styles affecting these components
    <div className='state-withholding'>
      {!isMultiLocation && (
        <SingleLocLayout type={type} fein={feins[0]} item={item} />
      )}
      {isMultiLocation && (
        <MultiLocLayout type={type} feins={feins} item={item} />
      )}
    </div>
  )
}

const MultiLocLayout = ({
  item,
  feins,
  type
}: {
  item: Item
  feins: FeinsQuery['feins']
  type: StateTaxType
}) => {
  const { t } = useTranslation('ponc')
  return (
    <>
      <Table className='state-table'>
        <Head>
          <Row>
            <HeadingCell>{t(`${item.key}.stateMultiLoc`)}</HeadingCell>
            <HeadingCell className='text-right'>
              {t(`${item.key}.taxAccount`)}
            </HeadingCell>
          </Row>
        </Head>
      </Table>
      <FeinInfoTable item={item} feins={feins} type={type} />
    </>
  )
}

const FeinInfoTable = ({
  item,
  feins,
  type
}: {
  item: Item
  feins: FeinsQuery['feins']
  type: StateTaxType
}) => {
  const { t } = useTranslation('ponc')

  return (
    <>
      {feins.map((fein, index) => {
        const states = fein.states
        return (
          <AccordionItem
            title={`${fein.name} ${t(
              `${item.key}.feinNumStates${states.length > 1 ? 'Plural' : ''}`,
              {
                count: states.length
              }
            )}`}
            id={`AccordionItem-${index}`}
            key={`AccordionItem-${index}`}
          >
            <Table className='ponc-table-row' key={`table-${index}`}>
              <Body key={`body-${index}`}>
                {states.map((state, index) => (
                  <StateTaxRow
                    fein={fein}
                    state={state}
                    key={index}
                    rate={findEinTaxRate(state.eins as [Ein], type)}
                    type={type}
                  />
                ))}
              </Body>
            </Table>
          </AccordionItem>
        )
      })}
    </>
  )
}

const SingleLocLayout = ({
  item,
  fein,
  type
}: {
  item: Item
  fein: FeinsQuery['feins'][0]
  type: StateTaxType
}) => {
  const { t } = useTranslation('ponc')
  return (
    <Table className='state-table'>
      <Head>
        <Row>
          <HeadingCell>{t(`${item.key}.state`)}</HeadingCell>
          <HeadingCell className='pr-12 text-right'>
            {t(`${item.key}.taxAccount`)}
          </HeadingCell>
        </Row>
      </Head>

      {fein.states.map((state, index) => (
        <Body key={`body-${index}`}>
          <StateTaxRow
            fein={fein}
            state={state}
            key={index}
            rate={findEinTaxRate(state.eins as [Ein], type)}
            type={type}
          />
        </Body>
      ))}
    </Table>
  )
}

const SingleStateRow = ({
  item,
  ein,
  rate
}: {
  item: Item
  ein: Ein
  rate?: React.ReactNode
}) => {
  const { t } = useTranslation('ponc')
  return (
    <React.Fragment key={`account-${ein.ein}`}>
      <div className='mb-2 mt-4'>{t(`${item.key}.number`)}</div>
      <div className='text-gray-75 flex mb-6'>
        {ein.ein === 'APPLIED FOR' ? (
          <Badge color='error' variant='statusLg'>
            {t('common.appliedFor')}
          </Badge>
        ) : (
          ein.ein
        )}
      </div>
      <div className='mb-2'>{t(`${item.key}.type`)}</div>
      <div className='text-gray-75 flex mb-6'>
        {rate ? (
          rate
        ) : (
          <Badge color='error' variant='statusLg'>
            {t('common.notProvided')}
          </Badge>
        )}
      </div>
    </React.Fragment>
  )
}

const StateTaxRow = ({
  fein,
  state,
  rate,
  type
}: {
  fein: FeinsQuery['feins'][0]
  state: FeinsQuery['feins'][0]['states'][0]
  rate?: React.ReactNode
  type: StateTaxType
}) => {
  const { t } = useTranslation('ponc')
  const client = useCompanyCode()
  return (
    <Row>
      <Cell>{state.name}</Cell>
      {rate ? (
        <Cell className='text-right'>
          <div className='pr-20'>{rate}</div>
          {state.eins
            .filter((ein) => isTaxType(ein, type))
            .map((ein, index) => (
              <div key={`${ein.ein}-${index}`} className='text-secondary flex'>
                <div className='flex w-full justify-end pr-4'>
                  {ein.ein === 'APPLIED FOR' ? (
                    <Badge color='error' variant='statusLg'>
                      {t('common.appliedFor')}
                    </Badge>
                  ) : (
                    ein.ein
                  )}
                </div>
                <div className='-mt-4'>
                  <Button
                    className='tax-account-link text-secondary'
                    onClick={() => {
                      window.location.assign(
                        `/mvc/${client}/Company/Setup/TaxAccounts?feinUuid=${
                          fein.id
                        }&ein=${
                          ein.id
                        }&redirect=${window.location.pathname.slice(1)}`
                      )
                    }}
                    iconLeft={<EditIcon accessibility='decorative' />}
                    size='auto'
                    variant='link'
                  ></Button>
                </div>
              </div>
            ))}
        </Cell>
      ) : (
        <Cell className='text-right'>
          <Badge color='error' variant='statusLg'>
            {t('common.notProvided')}
          </Badge>
        </Cell>
      )}
    </Row>
  )
}
