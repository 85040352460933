import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'
import { ChecklistItemKey, TaskStatus } from '../../models/checkListItemModels'
import { useUpdateTask } from '../../hooks/graph/mutation/useUpdateTask'
import { ItemSubTask } from '../common/ItemSubTask'
import { Item } from '@local/api/generated/gql/graphql'
import { disableItemActions } from '../../utils/status'
import { Trans, useTranslation } from 'react-i18next'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { Loader } from '../../common/loader'
import { Error } from '../../common/error'
import { useFeins } from '../../hooks/graph/useFeins'

export const LinkStateTax = ({
  isAdmin,
  closeFocus,
  item
}: {
  isAdmin: boolean
  item: Item
  closeFocus: () => void
}) => {
  const { t } = useTranslation('ponc')
  const { isLoading, updateTask } = useUpdateTask()

  const { data, loading, error } = useFeins()
  const feins = data && data.length ? data : undefined

  const complete = () => {
    updateTask(
      ChecklistItemKey.PAYROLL_TAX_SETUP_LINK_TOAST_TO_STATE_TAX,
      TaskStatus.COMPLETED
    )
    closeFocus()
  }

  if (loading) return <Loader />

  if (error || !feins) return <Error />

  const states = new Set<string>()
  feins.forEach(({ states: feinStates }) =>
    feinStates.forEach(({ name }) => states.add(name))
  )

  return (
    <ItemSubTask
      title={t(`${item.key}.header`)}
      content={
        <div>
          <p className='font-bold'></p>
          <p>{t(`${item.key}.stateTaxAccess`)}</p>
          <p className='my-6'>
            <Trans
              t={t}
              i18nKey={`${item.key}.followGuide`}
              components={[
                <a
                  className='underline'
                  href='https://central.toasttab.com/s/article/Toast-Payroll-State-Third-Party-Administrator-Linking'
                  target='_blank'
                  rel='noreferrer'
                  key={`${item.key}.followGuide`}
                >
                  {t(`${item.key}.guide`)}
                </a>
              ]}
            />
          </p>
          <Table>
            <Head>
              <Row>
                <HeadingCell>{t(`${item.key}.state`)}</HeadingCell>
              </Row>
            </Head>
            <Body>
              {[...states].map((state, index) => (
                <Row key={index}>
                  <Cell key={`state-${index}`}>
                    <div>{state}</div>
                  </Cell>
                </Row>
              ))}
            </Body>
          </Table>
        </div>
      }
      footer={
        <>
          <Button
            as='a'
            variant='secondary'
            className='w-full'
            iconRight={<LaunchIcon accessibility='decorative' />}
            target='_blank'
            href='https://central.toasttab.com/s/article/Toast-Payroll-State-Third-Party-Administrator-Linking'
          >
            {t(`${item.key}.findState`)}
          </Button>
          <Button
            disabled={isLoading || disableItemActions(item)}
            className='w-full'
            onClick={complete}
          >
            {isAdmin ? t('common.complete') : t(`${item.key}.confirm`)}
          </Button>
        </>
      }
    />
  )
}
