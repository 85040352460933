import {
  CallSupportIcon,
  CheckCircleSelectedFillIcon,
  CheckCircleUnselectedIcon,
  LockLockedIcon,
  RestaurantIcon
} from '@toasttab/buffet-pui-icons'
import { isEmpty } from 'lodash'
import { Assignee, Item, TaskStatus } from '@local/api/generated/gql/graphql'
import {
  CHECKLIST_URL_MAP,
  ChecklistItemKey
} from './models/checkListItemModels'
import { ChecklistItemStatus } from '@toasttab/buffet-patterns-onboarding-checklist'
import { ROUTES } from './routes'

export function mapTaskStatusToChecklistStatus(
  status: TaskStatus,
  isAdmin?: boolean,
  customerOnly?: boolean
): ChecklistItemStatus {
  if (isAdmin && customerOnly) return ChecklistItemStatus.LOCKED
  if (isAdmin && status === 'LOCKED') return ChecklistItemStatus.NOT_STARTED

  return {
    COMPLETED: ChecklistItemStatus.COMPLETED,
    LOCKED: ChecklistItemStatus.LOCKED,
    NOT_STARTED: ChecklistItemStatus.NOT_STARTED,
    ON_HOLD: ChecklistItemStatus.ON_HOLD,
    IN_PROGRESS: ChecklistItemStatus.IN_PROGRESS,
    // currently not defined in buffet patterns
    CANCELED: ChecklistItemStatus.LOCKED,
    SKIPPED: ChecklistItemStatus.LOCKED
  }[status]
}

export function getLockedIcon(
  status: TaskStatus,
  isAdmin: boolean,
  customerOnly?: boolean
) {
  return isAdmin && customerOnly && status !== 'COMPLETED'
    ? RestaurantIcon
    : status === 'COMPLETED' && isAdmin && customerOnly
    ? CheckCircleSelectedFillIcon
    : LockLockedIcon
}

export function getCheckListIcon(assignedTo: Assignee, isAdmin: boolean) {
  if (isAdmin === (assignedTo === 'ONBOARDING')) {
    return CheckCircleUnselectedIcon
  } else if (assignedTo === 'CUSTOMER') {
    return RestaurantIcon
  } else {
    return CallSupportIcon
  }
}

export function getProgressLabel(item: Item): string {
  return item.subItems === undefined || isEmpty(item.subItems)
    ? ''
    : ` [${
        item.subItems.filter((subItem) => subItem.status === 'COMPLETED').length
      }/${item.subItems.length}]`
}

export function calculateProgress(items: Item[]) {
  const itemList: Item[] = []
  itemList.push(...items)
  const subItems: Item[] = []

  while (!isEmpty(itemList)) {
    const item = itemList.pop()
    item?.subItems?.forEach((subItem) => {
      if (subItem.subItems === undefined || isEmpty(subItem.subItems)) {
        subItems.push(subItem)
      } else {
        itemList.push(subItem)
      }
    })
  }

  const completedItems = subItems.filter(
    (subItem) => subItem.status === 'COMPLETED'
  )
  return Math.round(20 + (completedItems.length / subItems.length) * 80) / 100
}

export const findItem = (
  items: Item[] = [],
  condition: (item: Item) => boolean
): Item | undefined =>
  items.reduce(
    (found, item) =>
      found ||
      (condition(item)
        ? item
        : findItem(item.subItems, condition) || undefined),
    undefined as Item | undefined
  )

export const disableEinItem = (
  item: Item,
  isAdmin: boolean,
  einEnabled: boolean
) =>
  (item.key ===
    ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_STATE_UNEMPLOYMENT_TAX ||
    item.key ===
      ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_STATE_WITHHOLDING_TAX) &&
  !isAdmin &&
  !einEnabled

export function updateRoute(item: Item, feinIds: Array<string> | undefined) {
  let route = CHECKLIST_URL_MAP[item.key].route
  if (item.key === ChecklistItemKey.PAYROLL_TAX_SETUP_SIGN_IRS_FORM_8655) {
    if (item.status === 'COMPLETED') {
      route = ROUTES.reports
    }
    if (item.status !== 'COMPLETED' && feinIds?.length === 1) {
      route = `/:companyCode/payroll/forms/8655/${feinIds[0]}`
    }
  }
  return route
}
