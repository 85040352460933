export const ROUTES = {
  profile: '/:companyCode/employees/:employeeId/onboarding',
  w4Landing: '/:companyCode/employees/:employeeId/forms/w4/landing',
  i9: '/:companyCode/employees/:employeeId/forms/i9',
  paymentMethod: '/:companyCode/employees/:employeeId/paymentMethod',
  dashboard: '/:companyCode/dashboard',
  i9Edit: '/:companyCode/employees/:employeeId/forms/i9',
  deliveryMethod: '/mvc/:companyCode/Company/Setup/PaycheckDelivery',
  requiredDocumentation: '/mvc/:companyCode/Company/NewHire/RequiredDocuments',
  syncJobs: '/mvc/:companyCode/Company/SystemSettings/ToastJobs',
  team: '/:companyCode/team/employees',
  einSetup: '/mvc/:companyCode/Company/Setup/TaxAccounts',
  missingInfo: '/:companyCode/payroll/tax/dashboard',
  summary: '/:companyCode/Company/Setup/CompanySummary',
  earnings: '/customer/masterEarnings.aspx',
  tips: '/mvc/:companyCode/Company/SystemSettings/POSTips',
  form8655: '/:companyCode/payroll/forms/8655',
  reports: '/mvc/:companyCode/Company/Reports/YearEnd'
}

export const TOASTWEB_ROUTES = {
  jobs: '/restaurants/admin/jobs'
}
