import * as React from 'react'
import {
  EarningsEtAl,
  PayCard,
  PaycheckDeliveryMethod,
  TipsSettings
} from './pay'
import { Item } from '@local/api/generated/gql/graphql'
import { RequiredDocumentation } from './team/RequiredDocumentation'
import { Jobs } from './team/Jobs'
import { SyncJobs } from './team/SyncJobs'
import { ChecklistItemKey } from '../models/checkListItemModels'
import { FederalTaxInfo } from './tax/federalTaxInfo'
import { LinkStateTax } from './tax/linkStateTax'
import { AddInvite } from './team/AddInvite'
import { MissingInfo } from './payroll/MissingInfo'
import { PayrollTraining } from './payroll/PayrollTraining'
import { PayrollSetup } from './payroll/PayrollSetup'
import { PrepareToRun } from './payroll/PrepareToRun'
import { StateTax, StateTaxType } from './tax/stateTax'
import { WelcomeScreen } from './welcome'
import { LinkPreviousProvider } from './switcher/linkPreviousProvider'

type FocusContentProps = {
  item: Item
  isAdmin: boolean
  closeFocus: () => void
  isSwitcher?: boolean
}

const FocusContentInner = ({
  item,
  isAdmin,
  closeFocus,
  isSwitcher
}: FocusContentProps) => {
  switch (item.key) {
    case ChecklistItemKey.PAYROLL_ACTIVITY_GET_STARTED_BUTTON_CLICK:
      return <WelcomeScreen />
    case ChecklistItemKey.PAYROLL_SWITCHER_LINK_PREVIOUS_PROVIDER:
      return (
        <LinkPreviousProvider
          closeFocus={closeFocus}
          isAdmin={isAdmin}
          item={item}
        />
      )
    case ChecklistItemKey.PAYROLL_PAY_PAYCHECK_DELIVERY_METHOD:
      return <PaycheckDeliveryMethod closeFocus={closeFocus} item={item} />
    case ChecklistItemKey.PAYROLL_PAY_OFFER_TOAST_PAY_CARD:
      return <PayCard closeFocus={closeFocus} item={item} />
    case ChecklistItemKey.PAYROLL_PAY_DEDUCTIONS_TIME_OFF:
      return (
        <EarningsEtAl closeFocus={closeFocus} isAdmin={isAdmin} item={item} />
      )
    case ChecklistItemKey.PAYROLL_PAY_TIPS_SETTINGS:
      return (
        <TipsSettings closeFocus={closeFocus} isAdmin={isAdmin} item={item} />
      )
    case ChecklistItemKey.PAYROLL_TEAM_EMPLOYEE_DOCS:
      return (
        <RequiredDocumentation
          isAdmin={isAdmin}
          closeFocus={closeFocus}
          item={item}
        />
      )
    case ChecklistItemKey.PAYROLL_TEAM_EMPLOYEE_JOBS:
      return <Jobs isAdmin={isAdmin} closeFocus={closeFocus} item={item} />
    case ChecklistItemKey.PAYROLL_TEAM_SYNC_JOBS:
      return <SyncJobs isAdmin={isAdmin} closeFocus={closeFocus} item={item} />
    case ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_FEDERAL_TAX:
      return (
        <FederalTaxInfo isAdmin={isAdmin} closeFocus={closeFocus} item={item} />
      )
    case ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_STATE_WITHHOLDING_TAX:
      return (
        <StateTax
          type={StateTaxType.WITHHOLDING}
          isAdmin={isAdmin}
          item={item}
        />
      )
    case ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_STATE_UNEMPLOYMENT_TAX:
      return (
        <StateTax
          type={StateTaxType.UNEMPLOYMENT}
          isAdmin={isAdmin}
          item={item}
        />
      )
    case ChecklistItemKey.PAYROLL_TAX_SETUP_LINK_TOAST_TO_STATE_TAX:
      return (
        <LinkStateTax isAdmin={isAdmin} closeFocus={closeFocus} item={item} />
      )
    case ChecklistItemKey.PAYROLL_TEAM_INVITE_TEAM:
      return <AddInvite isAdmin={isAdmin} closeFocus={closeFocus} item={item} />
    case ChecklistItemKey.PAYROLL_REVIEW_COMPLETE_TRAINING:
      return (
        <PayrollTraining
          isAdmin={isAdmin}
          closeFocus={closeFocus}
          item={item}
        />
      )
    case ChecklistItemKey.PAYROLL_REVIEW_MISSING_INFO:
      return (
        <MissingInfo isAdmin={isAdmin} closeFocus={closeFocus} item={item} />
      )
    case ChecklistItemKey.PAYROLL_REVIEW_CONFIRM_SETUP:
      return (
        <PayrollSetup
          isAdmin={isAdmin}
          closeFocus={closeFocus}
          item={item}
          isSwitcher={isSwitcher}
        />
      )
    case ChecklistItemKey.PAYROLL_REVIEW_PREPARE_TO_RUN_PAYROLL:
      return (
        <PrepareToRun isAdmin={isAdmin} closeFocus={closeFocus} item={item} />
      )
    default:
      // remaining keys are handled differently (e.g. links) or not at all
      return null
  }
}

export const FocusContent = (props: FocusContentProps) => (
  <div className='flex flex-col h-full px-6 py-4 overflow-y-auto font-sans type-default text-default'>
    <FocusContentInner {...props} />
  </div>
)
